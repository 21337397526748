<template>
  <div>
    <v-app-bar
        app
        min-height="100px"
        v-if="mobile"
    >
      <v-row align-content="center" justify="center">
        <v-col cols="2">
          <v-img
              @click="drawer = true"
              class="imgMobile"
              :src="require('@/assets/main/drawer.svg')"
              contain
              height="80"
          />
        </v-col>
        <v-col cols="8">
          <v-img
              class="imgMobile mx-auto"
              :src="require('@/assets/main/logoVitae.png')"
              contain
              @click="routing('Home')"
              height="80"
              max-width="80"
          />
        </v-col>
        <v-col cols="2">
          <v-img
              class="imgMobile"
              :src="require('@/assets/main/contact.svg')"
              contain
              height="80"
              @click="routing('Contact')"
          />
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
    >
      <v-list
          style="padding: 0 !important;"
          nav
      >
        <v-icon size="50" color="#1579A7" class="mt-7 ml-1" @click="drawer = false">mdi-close</v-icon>
        <div class="listGroup">
          <div v-for="(link, i) in navbarLinks" :key="i">

            <v-list-item
                v-if="!link.expand"
                :key="i"
                active-class="green--text text--lighten-1"
                avatar
                class="v-list-item"
            >
              {{$t(link.title)}}
            </v-list-item>

            <v-list-group
                v-else
                active-class="green--text text--lighten-1"
                :key="link.title"
                no-action
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(link.title) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                  v-for="sublink in link.expand"
                  @click="routing(sublink.route)"
                  :key="sublink.title"
              >
                <v-list-item-title class="linkDrawer">{{ $t(sublink.title) }}</v-list-item-title>
              </v-list-item>

            </v-list-group>

          </div>
        </div>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        v-if="!mobile"
        class="overflow-hidden"
        min-height="90px"
        v-bind:height="!toggle ? '275px' : ''"
        app
        color="#FFFFFF"
        v-bind:fixed="toggle"
        v-bind:absolute="!toggle"
        v-bind:style="!toggle ? 'margin-bottom: 100px' : ''"
    >
      <v-row justify="center" align="center" v-show="!toggle">
        <v-col cols="12">
          <v-img
              :src="require('@/assets/main/logoVitae.png')"
              contain
              v-bind:height="(width > 1900) ? 175 : 140"
          />
        </v-col>
        <v-col
            class="navbarLinks"
            v-for="link in navbarLinks"
            :key="link.title"
        >
          <v-menu offset-y z-index="0">
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <div v-bind="attrs"
                     v-on="on"
                     @focus="link.active = true"
                     @focusout="link.active = false"
                     tabindex="0"
                     ref="menus"
                     v-bind:style="(link.title === 'vitaeSportSante' && width > 1440) || (link.title === 'wantPractice' && $i18n.locale === 'en') ?
                      'padding-left:30px; padding-right:30px' : ''"
                     :class="{ 'linksHover': hover , 'linksActive': link.active}">
                  {{ $t(link.title) }}
                  <v-icon
                      v-if="link.expand"
                      dense
                      :color="hover ? '#6FC071' : '#808080'"
                  >
                    mdi-chevron-down
                  </v-icon>
                </div>
              </v-hover>
            </template>
            <v-list
                class="navbarSubmenu"
                v-bind:style="link.title === 'enterprise' || link.title === 'collectivity' ? 'padding-top: 24px !important' : ''"
            >
              <br v-if="width < 1500 && (link.title === 'enterprise' || link.title === 'collectivity')">
              <v-list-item
                  v-for="(item, index) in link.expand"
                  :key="index"
                  @click="routing(item.route)"
                  v-bind:style="index === 0 ? 'border-top: #a3ffa6 solid 3px' : ''"
              >
                <v-hover v-slot="{ hover }">
                  <v-list-item-title
                      class="navbarSubmenuLinksToggle"
                      :class="{ 'linksHover': hover}">
                    {{ $t(item.title) }}
                  </v-list-item-title>
                </v-hover>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" v-show="toggle">
        <v-col class="logoNavbar" style="width: 40px;">
          <v-img
              style="cursor: pointer;"
              @click="routing('Home')"
              :src="require('@/assets/main/logoVitae.png')"
              contain
              v-bind:height="(width > 1900) ? 75 : 60"
          />
        </v-col>
        <v-col
            class="navbarLinksToggle"
            v-for="link in navbarLinks"
            :key="link.title"
        >
          <v-menu offset-y z-index="0">
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <div v-bind="attrs"
                     v-on="on"
                     @focus="link.active = true"
                     @focusout="link.active = false"
                     tabindex="0"
                     ref="menus"
                     v-bind:style="(link.title === 'vitaeSportSante' && width > 1440) || (link.title === 'wantPractice' && $i18n.locale === 'en') ?
                      'padding-left:30px; padding-right:30px' : ''"
                     :class="{ 'linksHover': hover , 'linksActive': link.active}">
                  {{ $t(link.title) }}
                  <v-icon
                      v-if="link.expand"
                      dense
                      :color="hover ? '#6FC071' : '#808080'"
                  >
                    mdi-chevron-down
                  </v-icon>
                </div>
              </v-hover>
            </template>
            <v-list
                class="navbarSubmenuToggle"
                v-bind:style="link.title === 'enterprise' || link.title === 'collectivity' ? 'padding-top: 35px !important' : ''"
            >
              <v-list-item
                  v-for="(item, index) in link.expand"
                  :key="index"
                  @click="routing(item.route)"
                  v-bind:style="index === 0 ? 'border-top: #a3ffa6 solid 3px' : ''"
              >
                <v-hover v-slot="{ hover }">
                  <v-list-item-title
                      class="navbarSubmenuLinksToggle"
                      :class="{ 'linksHover': hover}">
                    {{ $t(item.title) }}
                  </v-list-item-title>
                </v-hover>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: ['mobile'],
  data: () => ({
    toggle: false,
    drawer: false,
    width: screen.width,
    navbarLinks: [
      {
        title: 'vitaeSportSante',
        active: false,
        expand: [
          {
            title: "presentation",
            route: 'PresentationVitae'
          },
          {
            title: "organizationGovernance",
            route: 'OrganisationGouvernance'
          },
          {
            title: "missions",
            route: 'Missions'
          },
          {
            title: "partner",
            route: 'Partners'
          }
        ]
      },
      {
        title: 'wantPractice',
        active: false,
        expand: [
          {
            title: "recommendationsPhysical",
            route: 'RecommendationsPhysical'
          }
        ]
      },
      {
        title: 'etablissmentSocial',
        active: false,
        expand: [
          {
            title: "autonomousResidence",
            route: 'Ephad'
          },
          {
            title: "establishmentForDisabled",
            route: 'EstablishmentForDisable'
          },
          {
            title: "APA",
            route: 'IntegrationSport'
          }
        ]
      },
      {
        title: 'enterprise',
        active: false,
        expand: [
          {
            title: "enterpriseSport",
            route: 'EnterpriseSport'
          },
          {
            title: "sensitizationDay",
            route: 'AwarenessDay'
          },
          {
            title: "conferencesDiagnostic",
            route: 'ConferenceDiagnosis'
          },
          {
            title: "formationsGestures",
            route: 'FormationGesturePosture'
          },
          {
            title: "adaptedActivities",
            route: 'PhysicalActivitiesEnterprise'
          }
        ]
      },
      {
        title: 'collectivity',
        active: false,
        expand: [
          {
            title: "collectivesActivities",
            route: 'Community'
          }
        ]
      },
      {
        title: 'etablissmentHealth',
        active: false,
        expand: [
          {
            title: "therapeuticAccompaniement",
            route: 'HealthInstitution'
          }
        ]
      },
      {
        title: 'conferencesFormations',
        active: false,
        expand: [
          {
            title: "conferencesSportAndHealth",
            route: 'ConferenceSportHealth'
          },
          {
            title: "conferencesDigital",
            route: 'ConferenceDigital'
          },
          {
            title: "formations",
            route: 'OurFormations'
          }
        ]
      },
      {
        title: 'vitaeHealthHouse',
        active: false,
        expand: [
          {
            title: "device",
            route: 'Device'
          },
          {
            title: "healthPro",
            route: 'HealthPro'
          },
          {
            title: "sportPro",
            route: 'SportPro'
          },
          {
            title: "communities",
            route: 'Communities'
          },
          {
            title: "joinNetwork",
            route: 'Contact'
          }
        ]
      }
    ]
  }),
  methods: {
    routing(nameRoute) {
      this.$vuetify.goTo(0)
      if (this.$route.name !== nameRoute) {
        this.$router.push({name: nameRoute}).then(() => {
          this.$vuetify.goTo(0)
        })
      }
    },
    handleScroll() {
      if (this.$router.history.current.name === 'Home') {
        if (window.scrollY > 200) {
          this.$set(this, 'toggle', true)
        } else {
          this.$set(this, 'toggle', false)
        }
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)

    if (window.location.pathname !== '/') {
      this.$set(this, 'toggle', true)
      this.$emit('routing', false)
    }

    this.$router.beforeEach((to, from, next) => {
      this.$vuetify.goTo(0)
      if (to.name === 'Home') {
        this.$emit('routing', true)
        this.$set(this, 'toggle', false)
      } else {
        this.$emit('routing', false)
        this.$set(this, 'toggle', true)
      }
      //  continue to next page
      next()
    })
  }
};
</script>

<i18n>
{
  "en": {
    "vitaeSportSante": "VITAE sport santé",
    "wantPractice": "I want to practice",
    "etablissmentSocial": "Medical and social establishments",
    "enterprise": "Business",
    "collectivity": "Community",
    "etablissmentHealth": "Health establishments",
    "conferencesFormations": "Conferences and training",
    "vitaeHealthHouse": "VITAE House of health sport",
    "presentation": "Presentation of the association",
    "organizationGovernance": "Organization and governance",
    "missions": "Our missions",
    "partner": "Partners",
    "computeActivity": "Calculate my level of physical activity",
    "recommendationsPhysical": "Recommendations in physical activity",
    "launchActivityPhysical": "Engage in an adapted physical activity",
    "autonomousResidence": "EHPAD and autonomous residence",
    "establishmentForDisabled": "Establishment welcoming people with disabilities",
    "APA": "Integration through sport",
    "sensitizationDay": "Awareness day",
    "enterpriseSport": "Corporate sport",
    "conferencesDiagnostic": "Conferences and diagnosis",
    "formationsGestures": "Gesture and posture training",
    "adaptedActivities": "The adapted physical activities offered",
    "collectivesActivities": "Activities related to communities",
    "sportAtSchool": "Developing sport at school",
    "therapeuticAccompaniement": "Our therapeutic support",
    "conferencesSportAndHealth": "Our conferences on sport, health and well-being",
    "conferencesDigital": "Our digital conferences",
    "formations": "Our training",
    "device": "Our device",
    "healthPro": "Health professionals",
    "sportPro": "Sports professionals",
    "communities": "Communities",
    "joinNetwork": "Join the network maison sport santé VITAE"
  },
  "fr": {
    "vitaeSportSante": "VITAE sport santé",
    "wantPractice": "Je souhaite pratiquer",
    "etablissmentSocial": "Etablissements médico-sociaux",
    "enterprise": "Entreprises",
    "collectivity": "Collectivités",
    "etablissmentHealth": "Etablissements de santé",
    "conferencesFormations": "Conférences et formations",
    "vitaeHealthHouse": " VITAE Maisons Sport-Santé",
    "presentation": "Présentation de l'association",
    "organizationGovernance": "Organisation et gouvernance",
    "missions": "Nos missions",
    "partner": "Partenaires",
    "computeActivity": "Calculer mon niveau d'activité physique",
    "recommendationsPhysical": "Les recommandations en activité physique",
    "launchActivityPhysical": "Se lancer dans une activité physique adaptée",
    "autonomousResidence": "EHPAD et résidence autonome",
    "establishmentForDisabled": "Etablissement accueillant des publics handicapés",
    "APA": "L'insertion par le sport",
    "sensitizationDay": "Journée de sensibilisation",
    "enterpriseSport": "Sport entreprise",
    "conferencesDiagnostic": "Conférences et diagnostic",
    "formationsGestures": "Formation gestes et postures",
    "adaptedActivities": "Les activités physiques adaptés proposées",
    "collectivesActivities": "Les activités en lien avec les collectivités",
    "sportAtSchool": "Développer le sport à l'école",
    "therapeuticAccompaniement": "Notre accompagnement à visée thérapeutique",
    "conferencesSportAndHealth": "Nos conférences sur le sport santé bien être",
    "conferencesDigital": "Nos conférences sur le numérique",
    "formations": "Nos formations",
    "device": "Notre dispositif",
    "healthPro": "Professionels de santé",
    "sportPro": "Professionels du sport",
    "communities": "Collectivités",
    "joinNetwork": "Intégrer le réseau maison sport santé VITAE"
  }
}
</i18n>

<style scoped>

.listGroup {
  margin-top: 21px;
  border-top: #6FC071 solid 2px;
}

.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 40px !important;
}

.v-list-item__title, .v-list-item__subtitle {
  white-space: normal;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #808080 !important;
}

.v-list-group--active > .v-list-group__header .v-list-item, .v-list-group--active > .v-list-group__header .v-list-item__content, .v-list-group--active > .v-list-group__header .v-list-group__header__prepend-icon .v-icon {
  color: #6FC071 !important;
}

.linkDrawer {
  white-space: normal;
  font-size: 0.9rem !important;
}

.imgMobile {
  margin-top: 40px;
}

@media screen and (min-width: 1500px){
  .navbarLinks {
    font-family: Comfortaa, sans-serif;
    text-align: center;
    color: #808080;
    font-size: 19px;
  }

  .navbarLinksToggle {
    font-family: Comfortaa, sans-serif;
    text-align: center;
    color: #808080;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbarSubmenu {
    font-family: Comfortaa, sans-serif;
    padding-top: 10px
  }
  .navbarSubmenuToggle {
    font-family: Comfortaa, sans-serif;
    padding-top: 23px
  }
}

@media screen and (max-width: 1500px){
  .navbarLinks {
    font-family: Comfortaa, sans-serif;
    text-align: center;
    color: #808080;
    font-size: 15px;
  }

  .navbarLinksToggle {
    font-family: Comfortaa, sans-serif;
    text-align: center;
    color: #808080;
    font-size: 13px;
  }
  .navbarSubmenu {
    font-family: Comfortaa, sans-serif;
    padding-top: 32px
  }
  .navbarSubmenuToggle {
    font-family: Comfortaa, sans-serif;
    padding-top: 25px
  }
}

.navbarSubmenuLinksToggle {
  font-size: 15px;
  color: #999999;
}

.linksHover {
  cursor: pointer;
  color: #6FC071;
}

.linksActive {
  color: #6FC071;
}

</style>
