import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../../views/Home.vue'),
    meta: {
      title: 'Vitaé Sport Santé',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contactUs" */ '../../views/desktop/ContactUs.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Contact',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
    // VITAE sport santé
  {
    path: '/presentation-vitae',
    name: 'PresentationVitae',
    component: () => import(/* webpackChunkName: "presentationVitae" */ '../../views/desktop/vitaeSportSante/PresentationVitae.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Présentation',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/organisation',
    name: 'OrganisationGouvernance',
    component: () => import(/* webpackChunkName: "organisationGouvernance" */ '../../views/desktop/vitaeSportSante/OrganisationGouvernance.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Organisation',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/missions',
    name: 'Missions',
    component: () => import(/* webpackChunkName: "missions" */ '../../views/desktop/vitaeSportSante/Missions.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Missions',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import(/* webpackChunkName: "partners" */ '../../views/desktop/vitaeSportSante/Partners.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Missions',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  // WantPractice
  {
    path: '/recommendations-physical',
    name: 'RecommendationsPhysical',
    component: () => import(/* webpackChunkName: "recommendationsPhysical" */ '../../views/desktop/WantPractice/RecommendationsPhysical'),
    meta: {
      title: 'Vitaé Sport Santé - Recommendations physiques',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/physical-activities',
    name: 'PhysicalActivities',
    component: () => import(/* webpackChunkName: "physicalActivities" */ '../../views/desktop/WantPractice/PhysicalActivities'),
    meta: {
      title: 'Vitaé Sport Santé - Activités physiques',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  // Establishments
  {
    path: '/ephad',
    name: 'Ephad',
    component: () => import(/* webpackChunkName: "ephad" */ '../../views/desktop/Establishments/EPHAD.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Ephad',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/establishment',
    name: 'EstablishmentForDisable',
    component: () => import(/* webpackChunkName: "establishmentForDisable" */ '../../views/desktop/Establishments/EstablishmentForDisable.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Etablissement',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/integration-sport',
    name: 'IntegrationSport',
    component: () => import(/* webpackChunkName: "integrationSport" */ '../../views/desktop/Establishments/IntegrationSport.vue'),
    meta: {
      title: 'Vitaé Sport Santé - L\'insertion par le sport',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  // Enterprise
  {
    path: '/enterprise-sport',
    name: 'EnterpriseSport',
    component: () => import(/* webpackChunkName: "enterpriseSport" */ '../../views/desktop/Enterprise/EnterpriseSport.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Sport entreprise',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/awareness-day',
    name: 'AwarenessDay',
    component: () => import(/* webpackChunkName: "awarenessDay" */ '../../views/desktop/Enterprise/AwarenessDay.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Journée de sensibilisation',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/conference-diagnosis',
    name: 'ConferenceDiagnosis',
    component: () => import( '../../views/desktop/Enterprise/ConferenceDiagnosis.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Conférence et diagnostic',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/formation-gesture-posture',
    name: 'FormationGesturePosture',
    component: () => import( '../../views/desktop/Enterprise/FormationGesturePosture.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Formation gestes et postures',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/physical-activities-enterprise',
    name: 'PhysicalActivitiesEnterprise',
    component: () => import( '../../views/desktop/Enterprise/PhysicalActivitiesEnterprise.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Les activités physiques adaptées',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  // Community
  {
    path: '/community',
    name: 'Community',
    component: () => import('../../views/desktop/Community/Community.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Collectivités',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  // HealthInstitution
  {
    path: '/health-institution',
    name: 'HealthInstitution',
    component: () => import('../../views/desktop/HealthInstitution/HealthInstitution.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Etablissement de santé',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  // ConferenceFormation
  {
    path: '/conference-sport-health',
    name: 'ConferenceSportHealth',
    component: () => import('../../views/desktop/ConferenceFormation/ConferenceSportHealth.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Conférences',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/conference-digital',
    name: 'ConferenceDigital',
    component: () => import('../../views/desktop/ConferenceFormation/ConferenceDigital.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Conférence numérique',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/our-formations',
    name: 'OurFormations',
    component: () => import('../../views/desktop/ConferenceFormation/OurFormations.vue'),
    meta: {
      title: 'Vitaé Sport Santé - Formations',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  // VitaeHealthHouse
  {
    path: '/device',
    name: 'Device',
    component: () => import('../../views/desktop/vitaeHealthHouse/Device'),
    meta: {
      title: 'Vitaé Sport Santé - Notre dispositif',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/health-pro',
    name: 'HealthPro',
    component: () => import('../../views/desktop/vitaeHealthHouse/HealthPro'),
    meta: {
      title: 'Vitaé Sport Santé - Professionnels de santé',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/sport-pro',
    name: 'SportPro',
    component: () => import('../../views/desktop/vitaeHealthHouse/SportPro'),
    meta: {
      title: 'Vitaé Sport Santé - Professionnels du sport',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/communities',
    name: 'Communities',
    component: () => import('../../views/desktop/vitaeHealthHouse/Communities'),
    meta: {
      title: 'Vitaé Sport Santé - Les collectivités',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
    // Activities
  {
    path: '/activities/adapted-physical-activities',
    name: 'AdaptedPhysicalActivities',
    component: () => import('../../views/desktop/Activities/AdaptedPhysicalActivities'),
    meta: {
      title: 'Vitaé Sport Santé - Les activités physiques',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/well-being-workshop',
    name: 'WellBeingWorkshop',
    component: () => import('../../views/desktop/Activities/WellBeingWorkshop'),
    meta: {
      title: 'Vitaé Sport Santé - Atelier bien être',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/balance-gym',
    name: 'BalanceGym',
    component: () => import('../../views/desktop/Activities/BalanceGym'),
    meta: {
      title: 'Vitaé Sport Santé - La gym équilibre',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/sports-therapy',
    name: 'SportsTherapy',
    component: () => import('../../views/desktop/Activities/SportsTherapy'),
    meta: {
      title: 'Vitaé Sport Santé - Sport thérapie',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/individual-support',
    name: 'IndividualSupport',
    component: () => import('../../views/desktop/Activities/IndividualSupport'),
    meta: {
      title: 'Vitaé Sport Santé - Prise en charge individuelle',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/nordic-walking',
    name: 'NordicWalking',
    component: () => import('../../views/desktop/Activities/NordicWalking'),
    meta: {
      title: 'Vitaé Sport Santé - La marche nordique',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/active-gym',
    name: 'ActiveGym',
    component: () => import('../../views/desktop/Activities/ActiveGym'),
    meta: {
      title: 'Vitaé Sport Santé - La gym active',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/olympics',
    name: 'Olympics',
    component: () => import('../../views/desktop/Activities/Olympics'),
    meta: {
      title: 'Vitaé Sport Santé - Les olympiades',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/memory-gym',
    name: 'MemoryGym',
    component: () => import('../../views/desktop/Activities/MemoryGym'),
    meta: {
      title: 'Vitaé Sport Santé - La gym mémoire',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/health-courses',
    name: 'HealthCourses',
    component: () => import('../../views/desktop/Activities/HealthCourses'),
    meta: {
      title: 'Vitaé Sport Santé - Les parcours santé',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/nutrition-workshop',
    name: 'NutritionWorkshop',
    component: () => import('../../views/desktop/Activities/NutritionWorkshop'),
    meta: {
      title: 'Vitaé Sport Santé - Les parcours santé',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/digital-workshop',
    name: 'DigitalWorkshop',
    component: () => import('../../views/desktop/Activities/DigitalWorkshop'),
    meta: {
      title: 'Vitaé Sport Santé - Atelier numérique',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/physical-activities-away',
    name: 'PhysicalActivitiesAway',
    component: () => import('../../views/desktop/Activities/PhysicalActivitiesAway'),
    meta: {
      title: 'Vitaé Sport Santé - Activité physique à distance',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/sleep-workshop',
    name: 'SleepWorkshop',
    component: () => import('../../views/desktop/Activities/SleepWorkshop'),
    meta: {
      title: 'Vitaé Sport Santé - Atelier sommeil',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/1',
    name: 'Activities1',
    component: () => import('../../views/desktop/Activities/ActivitiesForDisable'),
    meta: {
      title: 'Vitaé Sport Santé - Activité',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/2',
    name: 'Activities2',
    component: () => import('../../views/desktop/Activities/ActivitiesForDisable'),
    meta: {
      title: 'Vitaé Sport Santé - Activité',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  },
  {
    path: '/activities/3',
    name: 'Activities3',
    component: () => import('../../views/desktop/Activities/ActivitiesForDisable'),
    meta: {
      title: 'Vitaé Sport Santé - Activité',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez pratiquer une activité physique en toute sécurité et adaptée à votre état de forme ? Vitaé sport santé est une association loi 1901 composée d\'un bureau de bénévoles et d\'une équipe de professionnels diplômés dans le domaine du sport et la santé.'
        }
      ]
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
