<template>
  <v-app class="mainApp">
    <div v-bind:style="blockForHome && !mobile ? 'height: 315px;' : 'height: 100px;'"></div>
    <Navbar v-bind:mobile="mobile" v-on:routing="changeBlockSize"></Navbar>
    <ContactUs v-bind:mobile="mobile" v-if="$route.name !== 'Contact'"></ContactUs>
    <v-main>
      <router-view v-bind:mobile="mobile"></router-view>
    </v-main>
    <Footer v-bind:mobile="mobile"></Footer>
  </v-app>
</template>

<script>
import Navbar from '@/components/desktop/main/Navbar.vue'
import Footer from '@/components/desktop/main/Footer.vue'
import ContactUs from '@/components/desktop/main/ContactUs.vue'
export default {
  name: 'App',
  components: {
    Footer,
    Navbar,
    ContactUs
  },
  data: () => ({
    mobileWidth: 0,
    blockForHome: true
  }),
  methods: {
    changeBlockSize (homeRoute) {
      this.$set(this, 'blockForHome', homeRoute)
    }
  },
  created() {
    let locale = navigator.language.substring(0, 2)
    if (locale !== 'fr') {
      this.$i18n.locale = 'en'
    } else {
      this.$i18n.locale = locale
    }
    this.$set(this, 'mobileWidth', screen.width)
  },
  computed: {
    mobile: function () {
      return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.mainApp {
  font-family: Poppins, sans-serif;
}

.mainTitle {
  padding-top: 30px;
  padding-bottom: 50px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.mainTitleMobile {
  text-align: center;
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 23px;
  padding-bottom: 50px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.subTitle {
  padding-bottom: 50px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.mainTitleDark {
  padding-top: 30px;
  padding-bottom: 50px;
  color: #FFFFFF;
  font-family: Comfortaa, sans-serif;
}

.mainTitleDarkMobile {
  font-size: 25px;
  padding-top: 30px;
  padding-bottom: 50px;
  color: #FFFFFF;
  font-family: Comfortaa, sans-serif;
}

.v-main {
  padding: 0 !important;
}

@media screen and (min-width: 1500px){
  .content {
    padding-right: 200px;
    padding-left: 200px;
  }
  .mainTitle2Lines {
    text-align: center;
    padding-left: 600px;
    padding-right: 600px;
  }
}

@media screen and (max-width: 1500px){
  .content {
    padding-right: 100px;
    padding-left: 100px;
  }
  .mainTitle2Lines {
    text-align: center;
    padding-left: 350px;
    padding-right: 350px;
  }
}

.contentMobile {
  padding-right: 20px;
  padding-left: 20px;
}

h1 h2 h3 h4 h5 {
  font-weight: 500 !important;
  font-family: Comfortaa, sans-serif !important;
}
</style>
