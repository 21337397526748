<template>
  <v-btn
      v-if="!mobile"
      class="contactUsBtn"
      height="100"
      width="100"
      fab
      dark
      fixed
      bottom
      right
      :aria-label="$t('all')"
      color="#57939E"
      @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
  >
    <span v-html="$t('all')"></span>
  </v-btn>
</template>

<script>
export default {
  name: 'ContactUs',
  props: ['mobile'],
  data: () => ({
  }),
};
</script>

<i18n>
{
  "en": {
    "all": "Contact <br> us"
  },
  "fr": {
    "all": "Nous <br> contacter"
  }
}
</i18n>

<style scoped>

.contactUsBtn{
  letter-spacing: 0.5px;
  font-family: Comfortaa, sans-serif;
  font-weight: 600;
  font-size: 13px;
  bottom: 60px;
  right: 50px;
}

</style>
