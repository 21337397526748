<template>
    <v-row
        class="footer"
        justify="center"
        no-gutters
    >
      <v-col cols="3" v-if="!mobile"  align-self="start" style="max-height: 150px">
        <div class="logoFooter">
          <v-img
              class="logoOpacity"
              :src="require('@/assets/main/logoOpacity.png')"
              contain
              height="130"
          />
          <v-img
              class="healthLogo"
              :src="require('@/assets/main/homeHeathLogoOpacity.png')"
              contain
              height="60"
          />
        </div>
      </v-col>
<!--      <v-col v-bind:cols="mobile ? 12 : 2">-->
<!--        <div class="ma-5 footerSection">-->
<!--          <h3 class="titleSection">{{ $t('legalNotice') }}</h3>-->
<!--          <div>Lorem ipsum</div>-->
<!--          <div>Lorem ipsum</div>-->
<!--        </div>-->
<!--      </v-col>-->
<!--      <v-col v-bind:cols="mobile ? 12 : 2">-->
<!--        <div class="ma-5 footerSection">-->
<!--          <h3 class="titleSection">{{ $t('about') }}</h3>-->
<!--          <div>Lorem ipsum</div>-->
<!--          <div>Lorem ipsum</div>-->
<!--        </div>-->
<!--      </v-col>-->
      <v-col v-bind:cols="mobile ? 11 : 6">
        <div class="ma-5 footerSection">
          <h3 class="titleSection">{{ $t('contactUs') }}</h3>
          <div>vitae.sportsante@gmail.com</div>
          <div>06 33 73 41 61</div>
        </div>
      </v-col>
      <v-col v-if="!mobile" cols="3">
      </v-col>
      <v-col cols="12" v-if="mobile"  align-self="start" style="max-height: 150px">
        <div v-bind:class="mobile ? 'mobileLogo' : 'logoFooter'">
          <v-img
              class="logoOpacity"
              :src="require('@/assets/main/logoOpacity.png')"
              contain
              height="130"
          />
          <v-img
              class="healthLogo"
              :src="require('@/assets/main/homeHeathLogoOpacity.png')"
              contain
              height="60"
          />
        </div>
      </v-col>

      <v-col
          class="py-2 text-center"
          cols="12"
      >
        <div class="dateAndName">
          {{ new Date().getFullYear() }} — Vitae
        </div>
      </v-col>
    </v-row>
</template>

<script>
export default {
  name: 'Footer',
  props: ['mobile'],
  data: () => ({
  }),
};
</script>

<i18n>
{
  "en": {
    "contactUs": "Contact us",
    "about": "About",
    "legalNotice": "Legal Notice"
  },
  "fr": {
    "contactUs": "Nous contacter",
    "about": "A propos",
    "legalNotice": "Mentions légales"
  }
}
</i18n>

<style scoped>

.footer {
  font-size: 16px;
  padding-top: 20px;
  color: #57939E;
  background-color: #D5EEF4;
}

.footerSection {
  text-align: center;
}

.titleSection {
  padding-bottom: 15px;
  font-family: Comfortaa, sans-serif;
  font-weight: bold;
  font-size: 22px;
}

.healthLogo {
  margin-top: 60px;
  margin-right: 75px;
}

.logoOpacity {
  margin-left: 150px;
}

.mobileLogo {
  width: 360px;
  margin-left: -100px;
  display: inline-flex;
}

.logoFooter {
  display: inline-flex;
  padding-top: 20px;
  margin-left: -100px;
}

.dateAndName {
  padding-top: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border-top: aliceblue solid 2px;
}

</style>
